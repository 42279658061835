import { Component, Vue } from "vue-property-decorator";

import { SideNav } from "@/layout";
import { ConfirmDelete, Lightbox } from "@/components";
import { ErrorCode, ErrorMessage } from "@/constant";
import { RollcallModel } from "@/models";
import { Common } from "../Common";

export class SearchForm {
    time = '';
    clid = '';
    sid = '';
    cid = '';
}

type response = { [key: string]: unknown };

@Component<AdminRollcallIndexController>({
    components: {
        SideNav, ConfirmDelete, Lightbox
    }
})
export default class AdminRollcallIndexController extends Vue {
    private doubleClick = false;
    private searchForm = new SearchForm();
    private cityList: { [key: string]: any} = {};
    private schoolList: { [key: string]: any} = {};
    private classList: object = {};
    private rollcallList: object = {};
    private num = 0;
    private pageHtml = "";
    private rid = 0;
    private errNo = 0;
    private errorMsg = "";
    private openLB = false;

    private identity = window.localStorage.getItem('admin_identity') as string;
    private sid = window.localStorage.getItem('admin_sid') as string;
    private cid = '';
    private token = window.localStorage.getItem('adminToken') as string;

    public async created() {
        if (this.identity == "1") {
            this.cityList = await Common.getSchoolCity(); //取得有分校之縣市
        } else {
            this.cityList = await Common.getCity(this.sid); //取得該分校之縣市
            this.cid = this.cityList[0].cid;
            this.searchForm.cid = this.cid;

            const school = window.localStorage.getItem('admin_school') as string;
            this.schoolList[0] = {
                sid: this.sid,
                name: school,
            };
            this.searchForm.sid = this.sid;
            this.getClass(this.sid);
        }
        this.getRollcallList();
    }

    //取得該縣市之分校
    private async getSchool(cid: string) {
        if (this.identity == "1") { //權限為分校者，不連動下拉列表
            this.searchForm.sid = '';
            this.searchForm.clid = '';

            this.schoolList = await Common.getSchool(cid);
            this.classList = [];
        }
    }

    // 取得 該分校的班級列表
    private async getClass(sid: string) {
        this.searchForm.clid = '';
        this.classList = await Common.getClass(2, sid, 0);
    }

    public async getRollcallList() {
        if (this.identity == '2' && (this.searchForm.cid == '' || this.searchForm.sid == '')) {
            this.searchForm.cid = this.cid;
            this.searchForm.sid = this.sid;
            this.getClass(this.sid);
        }
        const item: { [key: string]: string } = {
            page: this.$route.query.page as string,
            time: this.searchForm.time,
            clid: this.searchForm.clid,
            sid: this.searchForm.sid,
            cid: this.searchForm.cid,
            token: this.token,
        }
        const data = await RollcallModel.getList(item) as response;
        Common.checkToken(data.ERR_CODE as number);
        this.num = data.num as number;
        this.rollcallList = data.list as object;
        this.pageHtml = data.pageHtml as string;
    }

    public delRollcall(id: number) {
        this.$emit("openConfirmDelete", true);
        this.rid = id;
    }

    public async deleteRollcall() {
        if (!this.doubleClick) {
            this.doubleClick = true;

            const item = {
                rid: this.rid,
                token: this.token,
            }
            this.errNo = await RollcallModel.del(item);
            this.$emit("closeConfirmDelete", false);
            Common.checkToken(this.errNo);
            this.errorMsg = ErrorMessage[this.errNo];
            this.openLB = true;
            this.doubleClick = false;
        }
    }

    private closeLB() {
        this.openLB = false;
        if (this.errNo === ErrorCode.Success) {
            this.$router.go(0); //reload page
        }
    }
}
